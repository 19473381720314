@media (max-width: 576px) {
  .video-img-container{
    width: 80% !important;
    margin: auto;
  }
  .TkAboutContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 5%;
    &__textContainer {  
      .TkAboutContent {
        text-align: center;
        &__text {
          .TkInstitucional-pleno {
            color: #004d96;
            font-size: 16px;
          }
        }
        &__img{
          margin-left: auto;
          margin-right: auto;
          width: 100%;
        }
        &__iconName { grid-area: iconname; text-align: center; font-size: 30px; font-weight: bold; }
        &__icon { grid-area: icon; margin: auto; }
        &__description { grid-area: description; font-size: 16; color: #004d96; text-align: center;}
        &__grid{
          display: grid;
          grid-template-areas: 
          'iconname''icon''description';
        }
        &__northDescription{
          text-align: center;
          color: #004d96;
          font-size: 18px;
          padding: 5%;
        }
      }
    }
  
  }
}

  @media (min-width: 576px) {
  .video-img-container{
    width: 80% !important;
    margin: auto;
  }
  .TkAboutContainer {
    display: flex;
    flex-direction: column;
    width: 70vw;
    margin: auto;

    &__textContainer {
      margin: 2rem;

      .TkAboutContent {
        &__text {
          .TkInstitucional-pleno {
            text-align: justify;
            color: #004d96;
            font-size: 16px;
          }
        }
        &__img{
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          margin-bottom: 10px;
        }
        &__iconName { grid-area: iconname; text-align: center; font-size: 30px; font-weight: bold; }
        &__icon { grid-area: icon; margin: auto; }
        &__description { grid-area: description; font-size: 16; color: #004d96; text-align: justify;}
        &__grid{
          display: grid;
          grid-template-areas: 
          'iconname description'
          'icon description';
          grid-template-columns: 250px;
          padding-top: 20px;
          padding-bottom: 10px;
          width: 100%;
          gap: 10px
        }
        &__northDescription{
          text-align: justify;
          color: #004d96;
          font-size: 18px;
          padding-left: 5%;
        }
      }
    }
  }
}