@import "reset";
@import "grid";
@import "colors";
@import "variables";
@import "form";
@import "alert";
@import "buttons";
@import "fonts";

:root {
  --primary-color: #{$primary-color}
}

.c-b {
  color: $color-black !important;
}

.c-p-c {
  color: $primary-color;
}

.c-a-g {
  color: $accent-gray;
}

.c-p-red {
  color: $primary-red;
}

.c-a-l-g {
  color: $accent-light-gray;
}

.c-a-ronchi {
  color: $color-ronchi;
}

.f-s-dot8em {
  font-size: $font-size-3;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.t-a-c {
  text-align: center;
}

.t-a-r {
  text-align: right;
}

.t-a-l {
  text-align: left;
}

.m-t-1rem {
  margin-top: 1em;
}

.m-l-1em {
  margin-left: 1em;
}

.m-r-1em {
  margin-right: 1em;
}

.m-t-10px {
  margin-top: .625em;
}

.m-t-15px {
  margin-top: .625em;
}

.m-t-20px {
  margin-top: .625em;
}

.m-t-30px {
  margin-top: 1.875em;
}

.m-t-40px {
  margin-top: 2.5em;
}

.p-5px {
  padding: .3125em;
}

.p-15px {
  padding: .9375em;
}

.p-r-10px {
  padding-right: 10px;
}

.p-l-10px {
  padding-left: 10px;
}

.m-l-1rem {
  margin-left: 1rem;
}

.m-r-1rem {
  margin-right: 1rem;
}

.m-b-1rem {
  margin-bottom: 1em;
}

.m-b-10px {
  margin-bottom: 10px;
}
.m-b-20px {
  margin-bottom: 20px;
}

.m10px {
  margin: .625em;
}

.o-h {
  overflow: hidden;
}

.f-w-b {
  font-weight: bold;
}

.f-s-09em {
  font-size: $font-size-3;
}

.f-s-14px {
  font-size: 14px;
}
.f-s-16px {
  font-size: $font-size;
}

.f-s-18pt {
  font-size: $font-size-5;
}

.d-flex {display: flex!important;}
.d-flex-i {display: inline-flex!important;}
.flex-d-c {flex-direction: column!important;}
.d-inline {display: inline!important;}
.a-i-c {align-items: center!important;}
.j-c-sb {justify-content: space-between!important;}
.j-c-sa {justify-content: space-around!important;}
.j-c-c {justify-content: center!important;}
.j-c-fe {justify-content: flex-end!important;}
.w-100 {width: 100%!important;}

.cursor-pointer {cursor: pointer!important;}

.u-s-n {user-select: none!important;}

.no-list-style {list-style: none!important;}
.no-margin { margin: 0!important;}
.no-padding { padding: 0!important;}
.o-y-auto { overflow-y: auto!important;}

.list-payment {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;

  li {
    &:not(:first-child) {
      margin-left: .3125em;
    }

    svg {
      width: 2em;
      height: 2em;
    }
  }
}

.img-responsive {
  width: 100%;
  height: auto;
}

.iframe-responsive {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.o-x {
  overflow-x: scroll
}

.w-a {
  width: auto
}

a.with-icon svg {
  width: 1em;
  height: 1em;
}

.link-instagram, .link-whatsapp, .link-facebook, .link-youtube {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  border-radius: $border-radius-2;
  color: $font-color-6;
  font-size: $font-size;
  box-shadow: 0 .1875em .625em $box-shadow-color-2;

  &:hover {
    color: $font-color-6;
  }

  svg {
    width: 1.1em;
    height: 1.1em;
  }
}

.link-instagram {
  background: $background-color-3;
  background: $gradient-6;
}

.link-whatsapp {
  border-radius: $border-radius-3;
  background: $gradient-5;
}

.link-facebook {
  background-color: $background-color-4;
}

.link-youtube {
  background-color: $background-color-5;
}

.credit_card {
  border: 1px solid $color-gray-chateau;
  border-radius: 3px;
  background-color: $color-ghost;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
}

.video-img-container {
  position: relative;
  cursor: pointer;

  > .play-button {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.slick-track {
  display: flex;
  gap: 10px;
}

.slick-slider .slick-slide {
  padding: 0;
}

@keyframes rotate-360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate-1-seg {
  animation: rotate-360 1s linear infinite;
}

h1, h2 {
  &.logo {
    text-align: center;
    margin-top: 1.25em;
    font-size: $font-size-4;

    svg {
      max-width: 5em;
      display: inline-block;
    }

    .te, .half-k, .y {
      fill: $fill-color;
    }

    .com-br, .line, .symbol-1, .symbol-2 {
      visibility: hidden;
    }

    &.black {
      .te, .half-k, .y {
        fill: $fill-color-2;
      }
    }
  }
  &.carousel-title {
    font-size: 1.3em;
    margin-top: 1.25em;
    padding: 0 0 0 5px;
    text-align: center;
  }
}


[data-state="closed"] {
  #gb-waw-iframe {
    width: 125px !important;
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: $primary-color;
  --toastify-color-warning: $accent-yellow;
  --toastify-color-error: $primary-red;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: $primary-color;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
                  to right,
                  #4cd964,
                  #5ac8fa,
                  #007aff,
                  #34aadc,
                  #5856d6,
                  #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

  // used to control the opacity of the progress trail
  --toastify-color-progress-bgo: .2;
}